.customer-header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: #5c57ff;
}

.customer-header__wrapper-content {
  width: 100%;
  height: 100%;
  padding: 13px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  align-items: center;
  align-content: center;
}

.customer-header__left-side {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 19px;
  align-items: center;
}

.customer-header__logo-link {
  height: 34px;
}

.customer-header__logo {
  display: none;
  @media (min-width: 992px) {
    display: block;
    width: 200px;
    height: 34px;
  }
}

.customer-header__logo-mobile {
  width: 34px;
  height: 34px;
  @media (min-width: 992px) {
    display: none;
  }
}

.customer-header__prev-page-icon {
  width: 16px;
  height: 26px;
}

.customer-header__right-side {
  padding-top: 2px;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 40px;
  justify-self: end;
  align-items: center;
  align-content: center;

  &--stripped {
    grid-template-columns: repeat(2, max-content);
  }
}

.customer-header__watch-lists-icon {
  width: 26px;
  height: 26px;
}

.customer-header__greeting {
  display: none;
  color: #ffffff;
  line-height: 1.71;
  font-size: 14px;
  font-weight: bold;

  @media (min-width: 768px) {
    display: block;
  }
}

.customerheader__logout-link {
  width: 100%;
  height: 100%;
}

.customer-header__sign-out-icon {
  width: 18px;
  height: 26px;
}

.customer-header__avatar {
  width: 26px;
  height: 26px;
}

.customer-header__info {
  text-decoration: none;
  grid-column-gap: 0;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    align-items: center;
    grid-column-gap: 10px;
  }
}
