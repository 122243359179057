.button {
  width: 319px;
  height: 60px;
  background-color: #5c57ff;
  font-size: 20px;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: unset;
  outline: 0;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(18, 12, 213, 0.5);
  }

  &:active {
    box-shadow: unset;
    background-color: #3f3cca;
    color: #5c57ff;
  }

  &:disabled {
    box-shadow: unset;
    background-color: #d2defc;
    cursor: not-allowed;
  }
}
