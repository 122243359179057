.colored-check-box {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.colored-check-box__tick {
  position: absolute;
  top: 28%;
  left: 15%;
  user-select: none;

  &--hidden {
    visibility: hidden;
  }
}
