.seller {
  width: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
}

.seller__info {
  width: 100%;
  padding: 13px 15px;

  @media (min-width: 744px) {
    padding: 15px;
  }

  @media (min-width: 1280px) {
    padding: 17px 15px;
  }

  &--convo-visible {
    padding-bottom: 15px;
  }
}

.seller__info-content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 158px;
  column-gap: 10px;
  border-bottom: none;

  @media (min-width: 375px) {
    grid-template-columns: 1fr 1fr;
    column-gap: unset;
  }

  &--fullscreen {
    grid-template-columns: 1fr;
  }
}

.seller__info-content-list {
  display: grid;
  grid-template-rows: 20px minmax(20px, auto) 20px;
  align-items: center;
  row-gap: 8px;

  @media (min-width: 744px) {
    row-gap: 13px;
  }

  &--left {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px 10px 10px;
    border-radius: 5px;
    background-color: #f7f7f7;

    @media (min-width: 375px) {
      min-width: 178px;
      width: 100%;
    }
  }

  &--right {
    justify-content: end;
    height: 100%;
    padding: 8px 10px 10px 0;

    @media (min-width: 1280px) {
      padding: 8px 0 10px;
    }
  }

  &--left-fullscreen {
    width: 100%;
    min-width: unset;
  }
}

.seller__info-content-list-item {
  display: grid;
  background-color: transparent;

  &--buttons {
    grid-template-columns: auto auto;
    column-gap: 12px;
  }
}

.seller__vehicle-source {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.seller__button {
  border: none;
  outline: none;
  background-color: transparent;

  &--new-convo {
    justify-self: end;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    color: #fd0100;
    cursor: pointer;
    user-select: none;
    z-index: 1;
  }
}

.seller__link {
  display: grid;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &--vdp-url {
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 5px;
  }

  &--view-all-convos {
    justify-self: end;
  }
}

.seller__link-text {
  color: #5c57ff;
}

.seller__external_link-icon {
  width: 12px;
  height: 12px;
}

.seller__info-text {
  font-size: 14px;
  font-weight: normal;

  &--dealer-name {
    line-height: 1;
  }
}

.seller__info-phone-number-wrapper {
  width: 100%;
  max-width: 178px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  column-gap: 5px;

  &--edit-phone-number {
    column-gap: unset;
    justify-content: space-between;
  }
}

.seller__info-phone-number-link {
  width: max-content;
  cursor: pointer;
  text-decoration: none;
}

.seller__info-phone-number-edit-input {
  width: calc(100% - 5px);
  height: 18px;
  font-size: 14px;
  font-style: italic;
  color: #5c57ff;
  background-color: #f7f7f7;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;

  &--error {
    border-bottom: 1px solid #fd0100;
  }
}

.seller__phone-number-btns {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 10px;
}

.seller__phone-number-btn {
  width: 18px;
  height: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &--add-btn {
    width: unset;
    height: unset;
    color: #5c57ff;
    font-size: 14px;
    font-weight: bold;
    text-align: left;

    @media (min-width: 1280px) {
      line-height: 1.5;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.seller__btn-icon {
  width: 100%;
  height: 100%;
}

.seller__switch-wrapper {
  display: grid;
  grid-template-columns: auto 30px auto;
  column-gap: 5px;
  align-items: center;
  justify-content: end;

  &--autoconvo {
    grid-template-columns: auto 30px;
  }
}

.seller__switch-option {
  font-size: 14px;
  line-height: 1.29;
  font-weight: normal;
  cursor: pointer;
}
