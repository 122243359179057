.sign-up {
  padding: 60px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sign-up__title {
  font-size: 24px;
  line-height: 1.5;
  font-weight: normal;
  margin-top: 17px;
}

.sign-up__inputs-wrapper {
  display: grid;
  width: 320px;
  grid-row-gap: 7px;
  margin-top: 10px;
}

.sign-up__button {
  margin-top: 15px;
}

.sign-up__sign-in-button {
  width: 320px;
  height: 60px;
  font-size: 20px;
  line-height: 1.35;
  letter-spacing: 1px;
  margin-top: 10px;
}
