.customer-additional-attributes {
  width: 100%;
  margin-top: 6px;
  display: grid;
  grid-row-gap: 8px;
  background-color: #fff;
  border-top: 1px dashed #8d8d8d;
}

.customer-additional-attributes__row {
  display: grid;
  grid-template-columns: 115px 1fr;
  grid-column-gap: 10px;

  &:first-of-type {
    padding-top: 13px;
  }

  @media (min-width: 620px) {
    grid-column-gap: 65px;
  }

  @media (min-width: 1280px) {
    grid-column-gap: 20px;
  }
}

.customer-additional-attributes__text {
  font-size: 12px;
  line-height: 1.5;

  &--bold {
    font-weight: bold;
  }
}
