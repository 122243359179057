.add-description-note-modal {
  width: 100vw;
  max-width: 380px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.add-description-note-modal__cross {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.add-description-note-modal__content-wrapper {
  width: 100%;
  padding: 25px 50px;
  display: grid;
  grid-row-gap: 25px;

  &--description {
    padding: 71px 50px 25px;
    background-color: #fff;
  }

  &--note {
    padding: 30px 50px 25px;
    background-color: #5c57ff;
  }
}

.add-description-note-modal__title {
  font-size: 16px;
  font-weight: bold;

  &--normal {
    font-weight: normal;
    color: inherit;
  }

  &--description {
    color: #1c1c1c;
  }

  &--note {
    color: #fff;
  }
}

.add-description-note-modal__textarea {
  width: 100%;
  height: 102px;
  border-radius: 5px;
  padding: 15px;
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  resize: none;
  outline: 0;
  border: none;

  &--description {
    color: #1c1c1c;
    background-color: #f0f0f0;

    &::placeholder {
      color: #878787;
    }
  }

  &--note {
    color: #3f3cca;
    background-color: #cfdeff;

    &::placeholder {
      color: #84abff;
    }
  }
}

.add-description-note-modal__buttons-wrapper {
  width: 100%;
  padding: 25px 50px 50px;
  background-color: #fff;
  display: grid;
  grid-row-gap: 15px;
}

.add-description-note-modal__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;

  &--add {
    &:disabled {
      background-color: #cfdeff;
    }
  }
}
