.assigned-customer-card {
  display: grid;
  row-gap: 13px;
  grid-template-rows: 150px auto;
  width: 100%;
  padding: 25px 25px 24px 25px;
  position: relative;
  overflow: hidden;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.05);
  text-decoration: none;
  min-height: 256px;

  &:after {
    content: "";
    position: absolute;
    width: 1100%;
    height: 1100%;
    top: 34px;
    right: -504%;
    background-color: #fff;
    transform-origin: 54% 0;
    transform: rotate(34deg);
    z-index: -1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 41px solid transparent;
    border-left: 62px solid #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    border-top-right-radius: 100%;
    width: 0;
  }

  @media (min-width: 376px) {
    max-width: 335px;
  }

  @media (min-width: 576px) {
    grid-template-rows: 120px auto;
    row-gap: 11px;
  }

  @media (min-width: 1280px) {
    width: auto;
    max-width: 300px;
  }

  @media (min-width: 1920px) {
    grid-template-rows: 187px auto;
  }
}

.assigned-customer-card__thumbnail-placeholder {
  width: 100%;
  height: 150px;
  background-color: #f7f7f7;
  background-image: url(../../assets/images/logo-gray.svg);
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 576px) {
    height: 120px;
  }

  @media (min-width: 1920px) {
    height: 187px;
  }
}

.assigned-customer-card__thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  display: block;

  @media (min-width: 576px) {
    height: 120px;
  }

  @media (min-width: 1920px) {
    height: 187px;
  }
}

.assigned-customer-card__wrapper {
  width: 100%;
}

.assigned-customer-card__info {
  display: grid;
  height: 100%;
  grid-column-gap: 15px;
  align-items: center;
  grid-template-columns: min-content auto;
  grid-template-rows: auto auto;
  grid-row-gap: 5px;

  @media (min-width: 576px) {
    grid-column-gap: 10px;
    grid-row-gap: 4px;
  }
}

.assigned-customer-card__avatar {
  width: 50px;
  height: 50px;
  align-self: start;
  grid-row-start: 1;
  grid-row-end: 3;

  @media (min-width: 576px) {
    width: 35px;
    height: 35px;
    grid-row-start: unset;
    grid-row-end: unset;
    margin-top: 6px;
  }
}

.assigned-customer-card__text {
  word-break: break-word;
  align-self: start;
  line-height: 1.5;

  @media (min-width: 576px) {
    min-height: 45px;
  }

  &--green {
    color: #36dec5;
    font-weight: bold;
    grid-column-start: 2;
    grid-column-end: 3;
    min-height: unset;
    align-self: end;

    @media (min-width: 576px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  &--date {
    color: #7f7f7f;
    font-size: 11px;
    font-weight: normal;
    min-height: unset;
    align-self: end;
  }
}
