.activity {
  width: 100%;
  max-height: 410px;
  padding: 0 25px;
  display: grid;
  grid-row-gap: 20px;
  background-color: #fff;
  overflow-y: auto;

  @media (min-width: 1920px) {
    max-height: 607px;
  }
}
