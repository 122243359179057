.main-info__main {
  position: relative;
  padding: 5px 20px 0;

  @media (min-width: 744px) {
    padding: 0;
  }

  &--watchlist {
    padding: 0 20px;

    @media (min-width: 744px) {
      padding: 0;
    }
  }
}

.main-info__edit-icon {
  position: absolute;
  cursor: pointer;

  &--edit {
    top: 2px;
    right: 20px;
    border-radius: 50%;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
    }

    @media (min-width: 744px) {
      right: 0;
    }
  }

  &--save {
    top: 3px;
    right: 56px;
    width: 26px;
    height: 26px;

    @media (min-width: 744px) {
      right: 36px;
    }
  }

  &--cancel {
    top: 3px;
    right: 20px;
    width: 26px;
    height: 26px;

    @media (min-width: 744px) {
      right: 0;
    }
  }
}

.main-info__row {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: end;

  &--margin {
    margin-top: 10px;
  }

  &--editable {
    padding-right: 56px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 1fr auto;
  }

  &--watchlist {
    align-items: start;
  }
}

.main-info__text {
  font-size: 14px;
  line-height: 1.5;
  word-break: break-word;
  display: inline-block;

  @media (min-width: 744px) {
    font-size: 20px;
  }

  &--distance {
    font-size: 20px;
  }

  &--bold {
    font-weight: bold;
  }

  &--edit {
    font-style: italic;
    color: #3f3cca;
    border-bottom: 1px solid #3f3cca;
    width: fit-content;
    outline: 0;
    cursor: text;

    &:empty:before {
      content: attr(data-placeholder);
      color: #868686;
    }
  }
  &--price {
    margin-top: 1px;
    font-size: 30px;
    line-height: 1.33;
    color: #36dec5;
  }

  &--price-watchlist {
    margin-top: 18px;
    font-size: 20px;
    line-height: 1.33;
    color: #36dec5;

    @media (min-width: 744px) {
      font-size: 20px;
    }

    @media (min-width: 744px) {
      margin-top: 17px;
    }
  }

  &--price-delisted {
    color: #96eade;
  }

  &--number {
    font-size: 20px;
  }

  &--delisted {
    color: #8a8a8a;
  }
}

.main-info__badge {
  width: 68px;
  height: 26px;
  position: absolute;
  right: 20px;
  top: 0px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #fd0100;
  background-color: #e0e0e0;
  display: grid;
  align-content: center;
  justify-content: center;

  @media (min-width: 744px) {
    right: 0;
  }
}

.main-info__delete-icon {
  width: 26px;
  height: 32px;
  position: relative;
  background-size: contain;
  background-image: url(../../../assets/icons/trash-black.svg);
  cursor: pointer;

  &::after {
    content: "Delete this vehicle from the database";
    position: absolute;
    top: calc(100% - 2px);
    right: calc(100% - 11px);
    width: 86px;
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    font-size: 10px;
    line-height: 1.4;
    color: #fd0100;

    transition: all 0.1s ease-in-out;
    transform: scale(0);
    transform-origin: top right;
    opacity: 0;
  }

  &:hover {
    background-image: url(../../../assets/icons/trash-red.svg);

    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.main-info__distance-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 9px;
  margin-bottom: 7px;
  margin-top: 16px;

  @media (min-width: 744px) {
    margin-top: 6px;
  }
}

.main-info__icon-map-point {
  width: 21px;
  height: 26px;
  margin-top: 2px;

  &--delisted {
    opacity: 0.5;
  }
}

.main-info__button {
  height: 46px;
  width: 46px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
  }
}

.main-info__editable-prices {
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 6px;
  row-gap: 2px;
  justify-content: start;
  align-items: end;

  @media (min-width: 744px) {
    margin-top: 0;
  }

  &--watchlist {
    margin-top: 10px;

    @media (min-width: 744px) {
      margin-top: 15px;
    }

    @media (min-width: 1280px) {
      margin-top: 12px;
    }
  }
}

.main-info__link-original-page {
  margin-top: 21px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 5px;
  text-decoration: none;
  align-self: start;
  align-items: center;
  cursor: pointer;

  @media (min-width: 744px) {
    margin-top: 24px;
  }

  @media (min-width: 1280px) {
    margin-top: 18px;
  }

  @media (min-width: 1920px) {
    margin-top: 20px;
  }
}

.main-info__link-original-page__text {
  font-size: 18px;
  font-weight: bold;
  color: #5c57ff;

  @media (min-width: 1280px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.main-info__link-original-page__icon {
  width: 12px;
  height: 12px;

  @media (min-width: 1280px) {
    width: 9px;
    height: 9px;
  }
}
