.input {
  width: 319px;
  height: 50px;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #e0e0e0;
  padding: 0 20px;
  font-size: 18px;
  color: #1c1c1c;
  outline: none;

  &::placeholder {
    color: #7f7f7f;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #fff;
  }

  &--error {
    border: solid 1px #fd0100 !important;
  }
}
