.save-search-modal {
  width: 100vw;
  max-width: 380px;
  padding: 66px 50px 50px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}

.save-search-modal__cross {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.save-search-modal__content-wrapper {
  width: 100%;
  display: grid;
  grid-row-gap: 25px;
}

.save-search-modal__title {
  font-size: 16px;
  font-weight: bold;
}

.save_search-modal__input-wrapper {
  width: 280px;
}

.save_search-modal__input {
  font-size: 16px;
}

.save-search-modal__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;
}
