.vehicle-checkbox {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.vehicle-checkbox__wrapper {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
}

.vehicle-checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.vehicle-checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 2px;
}

.vehicle-checkbox__text {
  color: #3f3cca;
  font-size: 14px;
  text-transform: uppercase;
  user-select: none;
}

.vehicle-checkbox__checkmark-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 10px;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.vehicle-checkbox__input:checked ~ .vehicle-checkbox__checkmark {
  background-color: #5d57f9;
  border-color: #5d57f9;
}

.vehicle-checkbox__input:checked ~ .vehicle-checkbox__checkmark-icon {
  opacity: 1;
}
