.control-buttons {
  width: 0;
  position: sticky;
  top: 86px;
  float: left;
  display: grid;
  row-gap: 10px;
}

.control-buttons__button {
  border: unset;
  width: 103px;
  height: 58px;
  border-radius: 0 20px 20px 0;
  background-color: #cfdeff;
  color: #5c57ff;
  line-height: 1.5;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;

  &--customers,
  &--salesReps {
    width: 111px;
    background-color: #5c57ff;
    color: #fff;
    font-weight: bold;
  }
}
