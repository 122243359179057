.vehicle-info {
  width: 100%;
}

.vehicle-info__details {
  margin-top: 17px;

  @media (min-width: 520px) {
    border-radius: 5px;
    overflow: hidden;
  }

  @media (min-width: 744px) {
    margin-top: 0;
  }
}

.vehicle-info__details-overview-wrapper {
  background-color: #fff;
  padding: 24px 0;
}

.vehicle-info__details-overview {
  max-height: 366px;
  height: max-content;
  display: grid;
  grid-row-gap: 8px;
  align-items: start;
  padding: 0 24px;
  overflow-y: auto;

  @media (min-width: 744px) {
    padding: 0 50px;
  }

  @media (min-width: 1920px) {
    max-height: 609px;
  }
}

.vehicle-info__details-overview-item {
  display: grid;
  grid-template-columns: 115px 1fr;
  grid-column-gap: 20px;

  @media (min-width: 1920px) {
    grid-column-gap: 55px;
  }
}

.vehicle-info__details-text {
  font-size: 12px;
  line-height: 1.5;
  word-break: break-word;

  &--bold {
    font-weight: bold;
  }

  &--link {
    color: #5c57ff;
  }
}

.vehicle-info__details-dealer {
  width: 100%;
  background-color: #fff;
}

.vehicle-info__details-lead {
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-row-gap: 13px;
  padding: 24px 50px;
}

.vehicle-info__details-activity-wrapper {
  background-color: #fff;
  padding: 25px 0;
}

.vehicle-info__details-activity {
  width: 100%;
}
