$height: 86px;

.admin-header {
  width: 100%;
  height: $height;
  position: fixed;
  background-color: #f7f7f7;
  z-index: 2;
}

.admin-header__wrapper {
  width: 100%;
  max-width: 730px;
  height: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  align-content: center;

  &--customer {
    grid-template-columns: repeat(2, 1fr);
  }
}

.admin-header__logo-link {
  width: fit-content;
  margin-top: 2px;
}

.admin-header__logo {
  width: 60px;
  height: 60px;
}

.admin-header__plus {
  width: 46px;
  height: 46px;
  border-radius: 100px;
  justify-self: center;
  position: relative;
}

.admin-header__plus-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  &--horizontal {
    width: 26px;
    height: 2px;
  }

  &--vertical {
    width: 2px;
    height: 26px;
  }
}

.admin-header__greeting-wrapper {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 25px;
  align-items: center;
  align-self: start;
  margin-top: 20px;
}

.admin-header__greeting {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
}

.admin-header__mock {
  height: $height;
}
