.customer-profile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 50px;
  background-color: #fff;

  @media (min-width: 576px) {
    padding-top: 85px;
  }

  @media (min-width: 992px) {
    padding-top: 188px;
  }

  @media (min-width: 1200px) {
    padding-top: 85px;
  }
}

.customer-profile__details-wrapper {
  display: grid;
  grid-row-gap: 15px;
  width: 305px;
  background-color: #fff;
  padding-top: 25px;
}

.customer-profile__avatar-wrapper {
  grid-gap: 10px;
  display: grid;
  justify-items: center;
}

.customer-profile__avatar {
  width: 100px;
  height: 100px;
}

.customer-profile__zip-code {
  width: 305px;
  padding: 0;
  height: 40px;
  background-color: #fff;
  border-radius: 0;
  border: none;
  border-bottom: solid 1px #f7f7f7;
  font-size: 13px;
  color: #1c1c1c;
  outline: none;
  font-size: 13px;

  &--error {
    border-bottom: solid 1px #fd0100;
    color: #fd0100;
  }
}

.customer-profile__input-wrapper {
  position: relative;
}

.customer-profile__password {
  font-size: 14px;
  position: relative;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #f0f0f0;
  padding: 0;
  width: 305px;
  height: 40px;

  &::placeholder {
    font-family: "DM SANS";
    font-size: 13px;
    line-height: 1.62;
  }

  &--error {
    border-bottom: solid 1px #fd0100;
    color: #fd0100;
  }
}

.customer-profile__input--error {
  position: absolute;
  top: calc(95% + 7px);
  right: 0;
  font-size: 10px;
  color: #fd0100;
}

.customer-profile__buttons-wrapper {
  margin-top: 8px;
  width: 305px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
}

.customer-profile__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;

  &:disabled {
    background-color: #cfdeff;
  }
}
