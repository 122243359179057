.assigned-customer-watch-list {
  width: 100%;
  padding: 20px;
  display: grid;

  @media (min-width: 576px) {
    grid-template-columns: min-content auto;
    grid-column-gap: 20px;
  }
}

.assigned-customer-watch-list__grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  padding-top: 20px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, 220px);
    padding-top: 0;
    align-self: start;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 250px);
  }
}

.assigned-customer-watch-list__stub {
  justify-self: center;
  width: 300px;
  margin-left: 17px;
  margin-top: 20px;

  @media (min-width: 576px) {
    margin-top: 58px;
  }
}

.assigned-customer-watch-list__title {
  font-size: 20px;
  color: #8d8d8d;
  letter-spacing: -0.5px;
  line-height: 1.5;
  text-align: center;
}

.assigned-customer-watch-list__icon-autoagents {
  width: 300px;
  display: block;
  margin: auto;
  margin-top: 25px;

  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }
}
