.watch-list-details {
  width: 100%;
  max-width: 1640px;
  margin: auto;
  padding: 20px 20px 20px;

  @media (min-width: 1200px) {
    padding: 20px 110px 56px;
  }

  @media (min-width: 1920px) {
    max-width: 2045px;
    padding: 20px 170px 56px;
  }
}

.watch-list__title {
  display: grid;
  grid-template-columns: auto;
  padding-bottom: 20px;
  text-align: center;

  @media (min-width: 744px) {
    display: block;
  }
}

.watch-list-details__text {
  font-size: 18px;
  line-height: 1.17;

  &--bold {
    font-weight: bold;
  }

  &--stub {
    line-height: 2;
  }
}

.watch-list-details__stub {
  display: grid;
  grid-template-rows: auto auto;
  gap: 36px;
  text-align: center;
}
