.assigned-customers {
  width: 100%;
  margin: auto;
  padding: 6px 20px 0;

  @media (min-width: 744px) {
    padding-top: 0;
  }

  @media (min-width: 1280px) {
    max-width: 1730px;
    padding: 0 75px 0;
  }
}

.assigned-customers__title {
  text-align: center;
  font-size: 20px;
  line-height: 1.33;
  font-weight: bold;

  &--empty {
    margin-top: 50px;
    font-weight: normal;
    letter-spacing: -1px;

    @media (min-width: 992px) {
      font-size: 40px;
      margin-top: 114px;
    }
  }
}

.assigned-customers__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  justify-content: center;
  place-items: center;

  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, 210px);
    place-items: unset;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
  }
}

.assigned-customers_icon-autoagents {
  width: 400px;
  max-width: 90vw;
  width: 32vw;
  max-width: 400px;
  display: block;
  margin: auto;
  margin-top: 25px;

  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }
}
