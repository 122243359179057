.info-from-seller {
  border-top: 1px dashed #8d8d8d;
  overflow: hidden;

  .info-from-seller__title {
    padding-top: 14px;
    display: grid;
    grid-template-columns: 1fr 12px;
    font-size: 12px;
    line-height: 1.5;
    cursor: pointer;
  }

  .info-from-seller_contents-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-in-out;
  }

  .info-from-seller_contents-wrapper-expanded {
    grid-template-rows: 1fr;
  }

  .info-from-seller__contents {
    overflow: hidden;
    display: grid;
    grid-template-columns: 115px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;

    @media (min-width: 1920px) {
      grid-column-gap: 55px;
    }

    .info-from-seller__contents-firstrow {
      margin-top: 10px;
    }

    .info-from-seller__contents-text {
      font-size: 12px;
      line-height: 1.5;
      word-break: break-word;
    }
  }

  .info-from-seller__contents__input {
    display: block;
    width: 100%;
    border: none;
    height: 18px;
    font-size: 12px;
    font-weight: bold;
    padding: 0;
  }
}
