.convo {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 245px;
  padding: 9px 0 8px;
  display: grid;
  grid-template-rows: auto max-content;
  background-color: #f0f0f0;

  @media (min-width: 744px) {
    min-height: 313px;
    padding-bottom: 15px;
  }

  @media (min-width: 1280px) {
    min-height: 273px;
  }
}

.convo__empty-message-list-wrapper {
  display: grid;
  align-items: start;
  margin-top: 8px;
}

.convo__empty-message-list {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-row-gap: 23px;
}

.convo__empty-message-list__title {
  font-size: 14px;
  font-weight: bold;
}

.convo__icon-autoagents {
  width: 114px;
  height: 100px;
}

.convo__message-list-wrapper {
  max-height: 284px;
  overflow-y: auto;
  padding: 0 15px;

  @media (min-width: 1024px) {
    max-height: 290px;
  }

  @media (min-width: 1920px) {
    max-height: 508px;
  }
}

.convo__message {
  display: inherit;
  width: calc(100% - 30px);
  min-height: 36px;
  max-height: 132px;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
}

.convo__message-list {
  width: 100%;
  display: grid;
  grid-row-gap: 9px;
  padding-bottom: 9px;
}

.message__textarea {
  width: 100%;
  min-height: 36px;
  max-height: 108px;
  padding: 9px 44px 9px 15px;
  outline: 0;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
  color: #1c1c1c;
  word-break: break-word;
  resize: none;
  background-color: #ffffff;

  &::placeholder {
    color: #878787;
  }
}

.message__send {
  position: absolute;
  right: 8px;
  bottom: 5px;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }
}
