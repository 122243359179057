.all-convos {
  width: 100%;
  padding: 23px 10px 0;

  @media (min-width: 1024px) {
    padding: 23px 20px 0;
  }
}

.all-convos__title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;

  &--empty {
    margin-top: 50px;
    font-weight: normal;

    @media (min-width: 1024px) {
      font-size: 40px;
      margin-top: 114px;
    }
  }
}

.all-convos__table {
  width: 100%;
  margin-top: 23px;
  display: grid;
  grid-row-gap: 5px;

  @media (min-width: 576px) {
    grid-row-gap: 10px;
  }

  @media (min-width: 1024px) {
    grid-row-gap: 2px;
  }
}

.all-convos__labels {
  display: none;

  @media (min-width: 1024px) {
    width: 100%;
    padding: 14px 30px 13px;
    grid-template-columns: 277fr 509fr 89px;
    grid-column-gap: 20px;
    text-decoration: none;
    background-color: #fff;
    display: grid;
    align-items: center;
    border-radius: 10px 10px 0 0;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 325fr 632fr 167fr;
  }
}

.all-convos__label {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: #7f7f7f;

  &--date {
    text-align: right;
  }
}

.all-convos__img {
  width: 32vw;
  max-width: 400px;
  display: block;
  margin: auto;
  margin-top: 25px;

  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }
}

.all-convos__scroll-to-top-arrow {
  width: 46px;
  height: 46px;
  transition: all 0.2s ease-in-out;
  position: fixed;
  right: 40px;
  bottom: 20px;
  opacity: 0;
  border-radius: 50%;

  &--open {
    cursor: pointer;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
  }

  &:active {
    box-shadow: unset;
  }
}
