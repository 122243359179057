.users-table {
  width: 100%;
  max-width: 730px;
  margin: auto;
  display: grid;
  grid-row-gap: 1px;
}

.users-table__row {
  width: 100%;

  min-height: 60px;
  padding: 5px 15px;
  display: grid;
  grid-template-columns: 50px 200px 322px 53px;
  grid-column-gap: 25px;
  background-color: #fff;
  align-content: center;
  align-items: center;
  cursor: pointer;

  &--first {
    grid-template-columns: 275px 322px 53px;
    cursor: unset;
  }

  &:hover {
    z-index: 1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  }
}

.users-table__title {
  font-size: 14px;
  font-weight: 500;
}

.users-table__text {
  font-size: 12px;
  word-wrap: break-word;

  &--first {
    font-size: 14px;
  }
}

.users-table__avatar {
  width: 50px;
  height: 50px;
}

.users-table__actions-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 16px);
  grid-column-gap: 20px;
}

.users-table__actions-icon {
  width: 16px;
  height: 16px;
  border: 0;
  background: none;
  position: relative;
  cursor: pointer;
}

.users-table__actions-icon::after {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  width: 32px;
  height: 32px;
}
