.additional-attributes {
  width: 100%;
  display: grid;
  grid-row-gap: 10px;
  margin-top: 6px;
  background-color: #fff;
  border-top: 1px dashed #8d8d8d;
}

.additional-attributes__row {
  display: grid;
  grid-template-columns: 115px 1fr;
  grid-column-gap: 20px;

  &:first-of-type {
    padding-top: 15px;
  }

  @media (min-width: 1920px) {
    grid-column-gap: 55px;
  }
}

.additional-attributes__text {
  font-size: 12px;
  line-height: 1.3;
  &--bold {
    font-weight: bold;
  }
}
