.rename-watch-list-modal {
  width: 100vw;
  max-width: 380px;
  padding: 66px 50px 50px;
  border-radius: 5px;
  background-color: #5c57ff;
  position: relative;
}

.rename-watch-list-modal__cross {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.rename-watch-list-modal__title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.31;
  color: #fff;
}

.rename-watch-list-modal__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-top: 25px;
  background-color: #ffffff;
  color: #5c57ff;
}

.rename-watch-list-modal__input {
  width: 100%;
  height: 42px;
  border-radius: unset;
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #cfdeff;
  padding: 0 10px;
  font-size: 16px;
  color: #fff;
  margin-top: 15px;

  &::placeholder {
    color: #cfdeff;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
  }
}
