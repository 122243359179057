.vehicle-list-table {
  width: 100%;
}

.vehicle-list-table__header {
  width: 100%;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 830fr 410fr;

  @media (min-width: 1920px) {
    margin-bottom: 8px;
    grid-template-columns: 1176fr 704fr;
  }
}

.vehicle-list-table__left-side-fields {
  display: grid;
  grid-template-columns: 150px 180fr 112fr 162fr 106fr 120fr;
  justify-content: center;
  align-items: center;

  @media (min-width: 1920px) {
    grid-template-columns: 150px 306fr 112fr 242fr 136fr 230fr;
  }
}

.vehicle-list-table__right-side-fields {
  display: grid;
  grid-template-columns: 120fr 120fr 170fr;
  justify-content: center;
  align-items: center;

  @media (min-width: 1920px) {
    grid-template-columns: 230fr 230fr 244fr;
  }
}

.vehicle-list-table__text {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  text-align: center;

  @media (min-width: 1920px) {
    font-size: 14px;
  }

  &--left {
    text-align: left;
  }

  &--source {
    padding-left: 20px;
  }
}

.vehicle-list-table__vehicles {
  display: grid;
  row-gap: 2px;
}
