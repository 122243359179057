.loader-component {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: grid;
  align-items: center;
  justify-items: center;
}

.loader-component__loader {
  width: 50px;
  height: 50px;
  border: 2px solid transparent;
  border-top-color: #5c57ff;
  border-radius: 50%;
  animation: loader-component__loader 2s linear infinite;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top-color: #ffce00;
    animation: loader-component__loader 3s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top-color: #36dec5;
    animation: loader-component__loader 1.5s linear infinite;
  }

  @keyframes loader-component__loader {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}
