.watchlists-customer__vehicles {
  padding-top: 25px;

  @media (min-width: 560px) {
    padding-top: 20px;
  }
}

.watchlists-customer__scroll-to-top {
  width: 46px;
  height: 46px;
  display: block;
  margin: 24px auto 0;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }
}
