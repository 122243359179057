$animation-diration: 400ms;

:export {
  animation-duration: $animation-diration;
}

.modal-component {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 100px 0;
  background-color: rgba(44, 44, 44, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 2;

  // Forcing GPU acceleration to avoid flickering in Safari on iOS 15
  transform: translate3d(0, 0, 0);

  &--open {
    animation: modal-component--open $animation-diration;
  }

  &--close {
    animation: modal-component--close $animation-diration;
  }

  @keyframes modal-component--open {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-component--close {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}

.modal-component__modal-wrapper {
  height: fit-content;
  max-height: 100%;
  $offset: 60px;

  &--open {
    animation: modal-component__modal-wrapper--open $animation-diration;
  }

  &--close {
    animation: modal-component__modal-wrapper--close $animation-diration;
  }

  @keyframes modal-component__modal-wrapper--open {
    from {
      transform: translateY($offset);
    }

    to {
      transform: translateY(0px);
    }
  }

  @keyframes modal-component__modal-wrapper--close {
    from {
      transform: translateY(0px);
    }

    to {
      transform: translateY($offset);
    }
  }
}

.modal-component,
.modal-component__modal-wrapper {
  &--open,
  &--close {
    animation-fill-mode: forwards;
  }
}
