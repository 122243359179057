.button {
  width: 140px;
  height: 46px;
  background-color: #fff;
  border: solid 1px #5c57ff;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #5c57ff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
    border: solid 1px #3f3cca;
    color: #3f3cca;
  }

  &:active {
    box-shadow: unset;
    border: solid 1px #3f3cca;
    color: #3f3cca;
  }

  &:disabled {
    box-shadow: unset;
    border: solid 1px #5c57ff;
    color: #5c57ff;
    cursor: not-allowed;
  }
}
