.other-conversation-modal__wrapper {
  padding: 0;

  > div {
    height: 100%;
  }

  @media (min-width: 576px) {
    > div {
      height: fit-content;
    }

    @media (min-height: 570px) {
      padding: 20px 0;
    }
  }
}

.other-conversation-modal {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding: 57px 0 25px;

  @media (min-width: 576px) {
    width: 400px;
    border-radius: 5px;
    padding: 56px 0 25px;

    @media (min-height: 570px) {
      height: fit-content;
      max-height: 100vh;
    }
  }
}

.other-conversation-modal__cross {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 41px;
  right: 41px;

  @media (min-width: 576px) {
    top: 25px;
    right: 25px;
  }
}

.other-conversation-modal__messages__wrapper {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 81px);
  display: grid;
  align-items: end;
  overflow-y: auto;

  @media (min-width: 576px) {
    @media (min-height: 570px) {
      max-height: 430px;
      overflow-y: auto;
    }
  }
}

.other-conversation-modal__messages {
  width: 100%;
  height: fit-content;
  max-height: 100%;
  padding: 0 57px 0 41px;
  display: grid;
  grid-row-gap: 20px;

  @media (min-width: 576px) {
    padding: 0 41px 0 25px;
  }
}
