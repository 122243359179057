.employee {
  background-color: #f7f7f7;
  padding-bottom: 50px;
}

.employee__page-wrapper {
  position: relative;
  z-index: 1;
  margin-top: 60px;
}
