.add-to-watch-list-modal {
  width: 100vw;
  max-width: 380px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.add-to-watch-list-modal__cross {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
