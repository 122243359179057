@mixin reset-grid-position {
  grid-row-start: unset;
  grid-row-end: unset;
  grid-column-start: unset;
  grid-column-end: unset;
}

.assigned-customer-profile__wrapper {
  display: grid;
  margin: auto;
  width: 100%;
  max-width: 385px;

  @media (min-width: 576px) {
    margin: unset;
    display: block;
    max-width: 205px;
    justify-items: center;
  }
}

.assigned-customer-profile {
  position: sticky;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: repeat(5, auto);
  row-gap: 6px;

  @media (min-width: 576px) {
    row-gap: 0;
    grid-template-columns: auto;
  }
}

.assigned-customer-profile__info {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 10px;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;

  @media (min-width: 576px) {
    @include reset-grid-position();
    column-gap: 15px;
    min-height: 72px;
  }
}

.assigned-customer-profile__avatar {
  height: 35px;
  width: 35px;
  margin-top: 4px;

  @media (min-width: 576px) {
    height: 60px;
    width: 60px;
  }
}

.assigned-customer-profile__text {
  font-size: 16px;
  line-height: 1.5;
  word-break: break-word;

  &--bold {
    font-weight: bold;
  }

  &--date {
    color: #7f7f7f;
    font-size: 10px;
    line-height: 1.3;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    justify-self: end;

    @media (min-width: 576px) {
      margin-top: 21px;
      font-size: 16px;
      line-height: 1;
      justify-self: unset;
      @include reset-grid-position();
    }
  }

  &--email {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;

    @media (min-width: 576px) {
      @include reset-grid-position();
      margin-top: 11px;
    }
  }

  &--phone-number {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 3;

    @media (min-width: 576px) {
      margin-top: 21px;
      line-height: 1;
      @include reset-grid-position();
    }
  }
}

.assigned-customer-profile__zip-code-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 11px;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-self: end;
  align-self: end;
  line-height: 1.5;
  margin-top: 5px;

  @media (min-width: 576px) {
    margin-top: 18px;
    line-height: 1;
    grid-column-gap: 9px;
    justify-self: unset;
    @include reset-grid-position();
  }
}

.assigned-customer-profile__map-point {
  width: 15px;
}

.assigned-customer-profile__button {
  width: 134px;
  height: 34px;
  line-height: 1.93;
  background-color: #1c1c1c;
  font-size: 14px;
  letter-spacing: 0.7px;
  font-weight: normal;
  margin-top: 3px;
  justify-self: center;

  @media (min-width: 576px) {
    width: 205px;
    height: 46px;
    margin-top: 21px;
  }
}
