.my-saved-searches {
  width: 100%;
  margin: auto;
  padding: 20px 20px 0;

  @media (min-width: 1280px) {
    max-width: 1730px;
    padding: 20px 75px 0;
  }
  @media (min-width: 1920px) {
    max-width: 2345px;
    padding: 20px 170px 0;
  }
}

.my-saved-searches__title {
  text-align: center;
  font-size: 18px;
  line-height: 1.33;
  font-weight: bold;
}

.my-saved-searches__grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  justify-content: center;

  @media (min-width: 514px) {
    grid-template-columns: repeat(auto-fill, 210px);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
  }
}
