.vehicles-grid__wrapper {
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 0;
  }
}

.vehicles-grid__page {
  padding-top: 20px;

  @media (min-width: 1200px) {
    padding-top: 25px;
    padding-left: 20px;
  }

  @media (min-width: 1900px) {
    padding-top: 20px;
    padding-left: 10px;
  }
}

.vehicles-grid__load-more-button {
  width: 189px;
  height: 46px;
  display: block;
  margin: auto;
  margin-top: 25px;
  font-size: 14px;
  letter-spacing: 0.7px;
  grid-column: 2;
}

.vehicles-grid__scroll-to-top-arrow {
  width: 46px;
  height: 46px;
  transition: all 0.2s ease-in-out;
  position: fixed;
  right: 40px;
  bottom: 20px;
  opacity: 0;
  background-image: url(../../assets/icons/scroll-to-top.svg);
  border-radius: 50%;
  z-index: 1;

  &--open {
    cursor: pointer;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(31, 64, 164, 0.5);
  }

  &:active {
    box-shadow: unset;
  }
}
