.customers {
  padding: 0 0 20px 0;
}

.customers__nav {
  margin: auto;
  width: fit-content;
  padding: 23px 0 17px 0;

  display: grid;
  grid-row-gap: 16px;

  @media (min-width: 505px) {
    grid-row-gap: unset;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 42px;
  }

  @media (min-width: 744px) {
    grid-row-gap: unset;
    grid-template-columns: repeat(2, max-content);
    padding: 23px 0;
  }
}

.customers__link {
  font-size: 20px;
  color: #5c57ff;
  justify-self: center;

  &--active {
    font-weight: bold;
    color: #1c1c1c;
    text-decoration: none;
  }
}
