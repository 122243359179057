.add-to-watch-list {
  width: 100%;
  background-color: #fff;
  padding: 69px 50px 27px 50px;
}

.add-to-watch-list__text {
  font-size: 16px;
  font-weight: bold;

  &--or {
    margin-top: 28px;
    text-align: center;
  }
}

.add-to-watch-list__dropdown-indicator {
  width: 16px;
  height: 9px;
}

.add-to-watch-list__button {
  width: 100%;
  height: 46px;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-top: 25px;
}
