.customer-main-info__main {
  position: relative;
  padding: 5px 20px 0;

  @media (min-width: 744px) {
    padding: 0;
  }
}

.customer-main-info__row {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;

  &--margin {
    margin-top: 10px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 1fr auto;
  }
}

.customer-main-info__text {
  font-size: 14px;
  line-height: 1.5;
  word-break: break-word;
  display: inline-block;

  @media (min-width: 744px) {
    font-size: 20px;
  }

  &--distance {
    font-size: 20px;
  }

  &--bold {
    font-weight: bold;
  }

  &--price {
    margin-top: 8px;
    font-size: 30px;
    line-height: 1.33;
    color: #36dec5;

    @media (min-width: 1024px) {
      margin-bottom: 1px;
    }
  }

  &--price-disclaimer {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 16px;
    margin-bottom: 3px;
  }
}

.customer-main-info__badge {
  width: 68px;
  height: 26px;
  position: absolute;
  right: 20px;
  top: 0px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #fd0100;
  background-color: #e0e0e0;
  display: grid;
  align-content: center;
  justify-content: center;

  @media (min-width: 744px) {
    right: 0;
  }
}

.customer-main-info__details {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
}

.customer-main-info__distance-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 9px;
  margin-top: 16px;

  @media (min-width: 744px) {
    margin-top: 6px;
  }
}

.customer-main-info__icon-map-point {
  width: 21px;
  height: 26px;
  margin-top: 2px;
}

.customer-main-info__vehicle-favorite {
  justify-self: end;
  width: 41px;
  height: 36px;

  @media (min-width: 1024px) {
    width: 41px;
    height: 36px;
  }

  @media (min-width: 1280px) {
    width: 23px;
    height: 20px;
    margin-top: 8px;
  }
}

.customer-main-info__row-credit {
  margin-top: 10px;

  .customer-main-info__input-credit {
    width: fit-content;
    height: auto;
    padding: 14px 28px;
    font-size: 14px;
    letter-spacing: 0.7px;
  }
}
