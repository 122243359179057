.sign-in {
  margin-top: 155px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sign-in__title {
  font-size: 24px;
  font-weight: normal;
  margin-top: 24px;
}

.sign-in__inputs-wrapper {
  display: grid;
  width: 320px;
  grid-row-gap: 9px;
  margin-top: 28px;
}

.sign-in__input {
  width: 100%;
  padding-left: 18px;
  border: solid 1px #d2defc;
  border-radius: 0%;

  &::placeholder {
    color: #1c1c1c;
  }
}

.sign-in__error {
  font-size: 18px;
  font-weight: normal;
  margin-top: 33px;
  margin-bottom: -2px;
  color: #fd0100;
}

.sign-in__forgot-password-wrapper {
  margin-top: 29px;
  font-size: 18px;

  a {
    color: #3f3cca;
  }
}

.sign-in__button {
  margin-top: 28px;
}

.sign-in__sign-up-button {
  width: 320px;
  height: 60px;
  font-size: 20px;
  line-height: 1.35;
  letter-spacing: 1px;
  margin-top: 10px;
}
