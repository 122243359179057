.watch-list-details {
  width: 100%;
  max-width: 520px;
  padding: 17px 25px;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 23px;

  @media (min-width: 560px) {
    padding: 15px 0;
  }
  @media (min-width: 1024px) {
    width: 790px;
    max-width: unset;
  }
  @media (min-width: 1280px) {
    width: 1060px;
  }
}
.watch-list-details__logo {
  width: 40.7px;
  height: 40px;
}
.watch-list-details__info-wrapper {
  text-align: right;
  @media (min-width: 744px) {
    margin-top: 1px;
  }
}
.watch-list-details__text {
  font-size: 20px;
  line-height: 1.4;
  color: #fff;
  word-break: break-word;
  &--bold {
    font-weight: bold;
  }
  @media (min-width: 560px) {
    font-size: 24px;
    line-height: 1.25;
  }
  @media (min-width: 1024px) {
    display: inline-block;
  }
}
