.saved-search-details {
  width: 100%;
  max-width: 1640px;
  margin: auto;
  padding: 0 20px;

  @media (min-width: 1900px) {
    max-width: 2045px;
    display: grid;
    grid-template-columns: minmax(280px, auto) 1fr;
  }
}
