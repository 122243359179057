.watch-list-vehicle-details {
  width: 100%;
  max-width: 520px;
  padding: 10px 25px 8px;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 25px;

  @media (min-width: 620px) {
    padding: 10px 0;
  }

  @media (min-width: 1024px) {
    width: 790px;
    max-width: unset;
  }

  @media (min-width: 1280px) {
    width: 1060px;
    max-width: unset;
  }
}

.watch-list-vehicle-details__controls-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 25px;
  align-items: center;
}

.watch-list-vehicle-details__logo {
  width: 40.7px;
  height: auto;
  display: none;

  @media (min-width: 744px) {
    display: block;
  }
}

.watch-list-vehicle-details__icon {
  cursor: pointer;
}

.watch-list-vehicle-details__info-wrapper {
  text-align: right;
}

.watch-list-vehicle-details__text {
  font-size: 18px;
  line-height: 1.33;
  color: #fff;
  word-break: break-word;

  &--bold {
    font-weight: bold;
  }

  @media (min-width: 744px) {
    font-size: 20px;
    line-height: 1.5;
    display: inline-block;
  }

  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 1.25;
  }
}

.watch-list-vehicle-details__btns {
  display: inline-block;
}
