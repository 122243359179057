.vehicle-details {
  width: 100%;
  max-width: 520px;
  padding-top: 20px;
  margin: auto;
  display: grid;
  grid-template-areas:
    "main-info"
    "vehicle-gallery"
    "vehicle-info";

  @media (min-width: 744px) {
    padding: 20px 112px 0;
    max-width: 1014px;
    grid-template-areas:
      "vehicle-gallery"
      "main-info"
      "vehicle-info";
    grid-row-gap: 17px;
  }

  @media (min-width: 1024px) {
    width: 790px;
    margin: auto;
    padding: 20px 0;
    grid-template-columns: 390px 1fr;
    grid-template-areas:
      "vehicle-gallery vehicle-gallery"
      "vehicle-info main-info";
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-content: start;
  }

  @media (min-width: 1280px) {
    width: 100%;
    max-width: 1920px;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 50px;
    align-content: start;
    justify-content: center;
    grid-template-areas:
      "vehicle-gallery main-info"
      "vehicle-gallery vehicle-info"
      "vehicle-gallery .";
    grid-column-gap: 50px;
    padding: 20px 110px 0;
  }
}

.vehicle-details__main-info {
  grid-area: main-info;
}

.vehicle-details__vehicle-gallery {
  grid-area: vehicle-gallery;
  margin-top: 17px;

  @media (min-width: 744px) {
    margin-top: 0;
  }

  @media (min-width: 1280px) {
    width: 48.4vw;
    max-width: 790px;
  }
}

.vehicle-details__vehicle-info {
  grid-area: vehicle-info;
}
