.vehicle-employee-description {
  width: 100%;
}

.vehicle-employee-description__title {
  margin-left: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: #1c1c1c;

  @media (min-width: 620px) {
    margin-left: 15px;
  }
}

.vehicle-employee-description__text {
  width: 100%;
  margin-top: 4px;
  background-color: rgba(54, 222, 197, 0.25);
  border-radius: 5px;
  padding: 15px 25px;
  font-size: 14px;
  line-height: 1.5;
  font-style: italic;
  word-break: break-word;
  white-space: pre-line;

  @media (min-width: 620px) {
    padding: 15px;
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
    padding: 23px 50px;
  }

  @media (min-width: 1024px) {
    margin-top: 5px;
    padding: 14px 15px 13px;
  }
}
