.vehicles-filters {
  width: 100vw;
  max-width: 280px;
  height: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f7f7f7;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 2;
  padding: 60px 20px 80px;
  overflow-y: auto;
  transition: all 0.2s ease-in-out;

  &--closed {
    transform: translateX(-100%);
  }

  @media (min-width: 1900px) {
    padding: 20px;
    box-shadow: unset;
    transition: none;
    transform: translateX(0) !important;
    position: sticky;
    float: left;
    overflow-x: hidden;

    &--employee {
      // 707 is the calculated height of the vehicles-filters block
      // Increase or decrease when adding/removing content and/or paddings
      @media screen and (max-height: 1223px) {
        // 60 is the header size
        height: calc(100vh - 60px);
      }
    }

    &--customer {
      // 415 is the calculated height of the vehicles-filters block
      // Increase or decrease when adding/removing content and/or paddings
      @media screen and (max-height: 1049px) {
        // 60 is the header size
        height: calc(100vh - 60px);
      }
    }
  }
}

.vehicles-filters__search-wrapper {
  display: grid;
  grid-template-columns: 1fr 34px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px;
}

.vehicles-filters__input {
  width: 100%;
  height: 34px;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 0 10px;
  background-color: #fff;
  font-size: 14px;
  color: #1c1c1c;
  outline: 0;

  &::placeholder {
    color: #7f7f7f;
  }

  &--search {
    border-right: unset;
    border-radius: 5px 0 0 5px;
    padding: 0 8px;
  }

  &--zip {
    text-align: center;
  }

  &--error {
    color: #fd0100;
  }
}

.vehicles-filters__slider-wrapper {
  margin-top: 13px;
  margin-bottom: 17px;
}

.vehicles-filters__slider {
  margin: 41px 16px 0 8px;
}

.vehicles-filters__color-wrapper {
  display: grid;
  grid-template-columns: repeat(7, max-content);
  grid-row-gap: 13px;
  justify-content: space-between;
  margin-bottom: 2px;
}

.vehicles-filters__button {
  width: 100%;
  height: 100%;
  background-color: #1c1c1c;
  font-size: 14px;
  letter-spacing: 0.7px;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
  }

  &:active {
    box-shadow: unset;
    background-color: #000;
    color: #7f7f7f;
  }

  &:disabled {
    box-shadow: unset;
    background-color: #7f7f7f;
    cursor: not-allowed;
  }
}

.vehicles-filters__postal-wrapper {
  margin: 10px 0;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 10px;
  align-items: center;
}

.vehicles-filters__text {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

.vehicles-filters__dash {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.vehicles-filters__sort-wrapper {
  display: grid;
  grid-row-gap: 5px;
  margin-bottom: 15px;

  &--main-checkboxes {
    grid-row-gap: 20px;
  }
}

.vehicles-filters__dropdown-indicator {
  width: 12px;
  height: 6px;
}

.vehicles-filters__sort-controller {
  width: 100%;
  height: 19px;
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #1c1c1c;
  outline: 0;
  font-size: 14px;
  border-radius: 0;
  padding: 0;
}

.vehicles-filters__range-wrapper {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 10px;
}

.vehicles-filters__sources-wrapper {
  display: grid;
  grid-row-gap: 10px;
  margin: 20px 0;
}

.vehicles-filters__buttons-wrapper {
  margin-top: 20px;
  height: 46px;
  display: grid;
  grid-template-columns: 1fr 46px;
  grid-column-gap: 20px;
  position: sticky;
  bottom: 0;
}

.vehicles-filters__toggle {
  position: fixed;
  z-index: 2;
  top: 65px;
  left: 0;
  cursor: pointer;

  @media (min-width: 1900px) {
    display: none;
  }
}

.vehicles-filters__switch-wrapper {
  margin-bottom: 15px;
  font-size: 14px;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 5px;
  align-items: center;
  justify-content: end;
}
