.watch-list-vehicle-details {
  width: 100%;
  max-width: 520px;
  margin: auto;
  display: grid;
  grid-row-gap: 25px;
  justify-items: center;

  @media (min-width: 620px) {
    width: 520px;
    max-width: unset;
    padding-top: 25px;
  }

  @media (min-width: 1024px) {
    width: 790px;
    max-width: unset;
    grid-row-gap: 20px;
  }

  @media (min-width: 1280px) {
    width: fit-content;
    grid-row-gap: unset;
    grid-template-columns: 620px 390px;
    grid-column-gap: 50px;
  }
}

.watch-list-vehicle-details__left-side {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 25px;
}

.watch-list-vehicle-details__right-side {
  width: 100%;
  display: grid;
  grid-row-gap: 15px;
  grid-template-rows: repeat(2, max-content);
  @media (min-width: 620px) {
    grid-row-gap: 25px;
  }
  @media (min-width: 1280px) {
    grid-row-gap: 15px;
  }
}
