.editable-price__text {
  font-size: 12px;
  line-height: 1.5;
  color: #1c1c1c;

  &--value {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    color: #5c57ff;
    cursor: pointer;
  }

  &--title {
    padding-bottom: 2px;
  }

  &--delisted {
    color: #8a8a8a;
  }

  &--read-only {
    cursor: default;
  }
}

.editable-price__btn {
  height: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 0.75;
  color: #5c57ff;
  text-align: left;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.editable-price__input {
  outline: none;
  border: none;
  width: 100px;
  height: 30px;
  padding: 0;
  font-size: 24px;
  line-height: 0.75;
  color: #5c57ff;
  background-color: transparent;
}
