.switch {
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #9a9a9a;
  transition: 0.4s;

  &--active {
    background-color: #5c57ff;
  }
}

.switch__slider:before {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch__input:checked + .switch__slider:before {
  transform: translateX(10px);
}
